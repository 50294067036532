.mat-mdc-paginator-page-size-label,
.mat-mdc-paginator-range-label {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: var(--c-text-secondary);
}


.mat-mdc-paginator .mat-mdc-select-value {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    letter-spacing: 1.25px;
    color: var(--c-text-secondary);
}