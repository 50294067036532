:root {
  --spacing-4xs: 1px;
  --spacing-xxxs: 2px;
  --spacing-xxs: 4px;
  --spacing-xs: 8px;
  --spacing-s: 12px;
  --spacing-m: 16px;
  --spacing-l: 20px;
  --spacing-xl: 24px;
  --spacing-xxl-2: 32px;
  --spacing-xxl-4: 40px;
  --spacing-xxl-6: 48px;
  --spacing-xxl-8: 56px;
  --spacing-xxl-10: 64px;
  --spacing-xxl-12: 72px;
  --spacing-xxl-14: 80px;
  --spacing-xxl-19: 100px;
  --spacing-xxl-24: 120px;
}
