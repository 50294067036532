.Done {
    padding: var(--spacing-xs) var(--spacing-m);
    border-radius: 34px;
    border: solid 1px var(--c-done-status) !important;
    color: var(--c-done-status) !important;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Rejected {
    padding: var(--spacing-xs) var(--spacing-m);
    border-radius: 34px;
    border: solid 1px var(--c-reject-status) !important;
    color: var(--c-reject-status) !important;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
}

.Success {
    padding: var(--spacing-xs) var(--spacing-m);
    border-radius: 34px;
    border: solid 1px var(--c-success-status) !important;
    color: var(--c-success-status) !important;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
}

.Wating {
    padding: var(--spacing-xs) var(--spacing-m);
    border-radius: 34px;
    border: solid 1px var(--c-wating--status) !important;
    color: var(--c-wating--status) !important;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
}

.Cancelled {
    padding: var(--spacing-xs) var(--spacing-m);
    border-radius: 34px;
    border: solid 1px var(--c-cancelled--status) !important;
    color: var(--c-cancelled--status) !important;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
}

.Pending {
    padding: var(--spacing-xs) var(--spacing-m);
    border-radius: 34px;
    border: solid 1px var(--c-pending--status) !important;
    color: var(--c-pending--status) !important;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
}