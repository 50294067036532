.c-white {
  color: var(--c-white) !important;
}

.c-black {
  color: var(--c-black) !important;
}

.c-primary {
  color: var(--c-primary-40) !important;
}

.c-txt-primary {
  color: var(--c-text-primary) !important;
}

.c-txt-secondary {
  color: var(--c-text-secondary) !important;
}

.c-txt-tertiary {
  color: var(--c-text-tertiary) !important;
}
