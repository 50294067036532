* {
  font-family: Roboto;
}
.full-width {
  width: 100%;
}

.custom-dialog .mat-mdc-dialog-surface {
  border-radius: 8px !important;
}

button.primary-action,
button.secondary-action {
  text-transform: uppercase;
  border-radius: 8px;
  letter-spacing: var(--spacing-4xs);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-family: Roboto;
  padding: 0 11px;
  height: 36px;

  .mdc-button__label {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  i.fa-regular {
    font-size: 18px;
  }

  mat-icon {
    width: 22px !important;
    height: 22px !important;
    font-size: 22px !important;
  }
}

button.primary-action {
  color: var(--c-gp-hermes-white) !important;

  mat-spinner circle {
    stroke: var(--c-gp-hermes-white) !important;
  }
}

button.primary-action:disabled {
  color: var(--c-neutral-80) !important;
  background-color: transparent !important;

  mat-spinner circle {
    stroke: var(--c-neutral-80) !important;
  }
}

button.secondary-action {
  color: var(--c-primary-40) !important;

  mat-spinner circle {
    stroke: var(--c-primary-40) !important;
  }
}

button.secondary-action:disabled {
  color: var(--c-neutral-80) !important;

  mat-spinner circle {
    stroke: var(--c-neutral-80) !important;
  }
}

/* loading button */
.btn-spinner-loader {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 20px;
  }

}

button .btn-spinner-loader:not(:required) {
  opacity: 0;
  transition: 0.3s margin ease-out, 0.2s opacity ease-out;
}

button.loading .btn-spinner-loader {
  opacity: 1;
  transition: 0.3s margin ease-out, 0.2s opacity ease-out;
}

button.loading .mdc-button__label {
  opacity: 0;
  transition: 0.3s margin ease-out, 0.2s opacity ease-out;
}

i.action-ic {
  font-size: 16px;
  position: relative;
  bottom: 5px;
}

/* loading button end*/

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  padding: var(--spacing-xxl-8) var(--spacing-xxl-24);
}

.m-0 {
  margin: 0;
  color: var(--c-neutral-99);
}

.f-1 {
  flex: 1;
}

.d-flex {
  display: flex;
}

.fd-row {
  flex-direction: row;
}

.fd-column {
  flex-direction: column;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.jc-center {
  justify-content: center;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-space-around {
  justify-content: space-around;
}

.jc-space-evenly {
  justify-content: space-evenly;
}

.ai-start {
  align-items: flex-start;
}

.ai-end {
  align-items: flex-end;
}

.ai-center {
  align-items: center;
}

.ai-space-between {
  align-items: space-between;
}

.ai-space-around {
  align-items: space-around;
}

.ai-space-evenly {
  align-items: space-evenly;
}