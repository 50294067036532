html, body { height: 100%; }
body { margin: 0; font-family: Nunito, "Arial", sans-serif; height: min-content; min-height: 100% }
h1, h2, h3, h4, h5, h6, p { margin: 0 }

body.light-mode .mat-app-background,
body.light-mode.mat-app-background,
body.dark-mode .mat-app-background,
body.dark-mode.mat-app-background {
  background-color: var(--c-bg) !important;
}


body.dark-mode input {
  color: var(--c-text-secondary);
  caret: var(--c-text-secondary);
}

figure { margin: 0 }

a { text-decoration: none }

*, *::after, *::before {box-sizing: border-box;}
