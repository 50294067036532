@import "assets/styles/reset.css";
@import "assets/styles/spacing.css";
@import "assets/styles/material-reset.scss";
@import "assets/styles/variables.scss";
@import "assets/styles/general.css";
@import "assets/styles/status.scss";

@import "assets/styles/typography/font-faces.css";
@import "assets/fonts/fontawsome/css/all.min.scss";
@import "assets/styles/typography/headings.css";
@import "assets/styles/typography/bodies.css";

@import "assets/styles/colors/light_colors.scss";
@import "assets/styles/colors/dark_colors.scss";
@import "assets/styles/colors/material_theme.scss";
@import "assets/styles/colors/colors.css";
@import "assets/styles/breakpoints";