@font-face {
  font-family: Nunito;
  src: local(Nunito-Regular),
    url(../../fonts/static/Nunito-Regular.woff2) format("woff2"),
    url(../../fonts/static/Nunito-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Nunito;
  src: local(Nunito-Medium),
    url(../../fonts/static/Nunito-Medium.woff2) format("woff2"),
    url(../../fonts/static/Nunito-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Nunito;
  src: local(Nunito-SemiBold),
    url(../../fonts/static/Nunito-SemiBold.woff2) format("woff2"),
    url(../../fonts/static/Nunito-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}
